import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { Link as Scroll } from "react-scroll"

const Features = () => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Features"
          pagepath="/features"
          pagedesc="Features | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
        />
        <main className="function">
          <section className="function_introduction">
            <div className="function_introduction_container relative">
              <div>
                <h1 className="function_title mb-5">Features</h1>
                <p className="function_lead">
                  This is an introduction to PARKLoT's functionality
                  <br />
                  that allows you to easily start a campaign.
                  <br />
                  It has all the features necessary to hold a campaign,
                  <br />
                  including instant win, automatic draws, results notification,
                  <br />
                  <span className="tracking-tight">
                    reporting functions, and address collection.
                  </span>
                </p>
              </div>
              <div className="function_introduction_img">
                <StaticImage
                  layout="constrained"
                  alt="Features"
                  src="../../images/features/top.png"
                />
              </div>
            </div>
          </section>
          <div className="mobile-spacing"></div>

          <section className="function_btn-background">
            <div className="function_btn_list flex m-auto">
              <Scroll
                className="function_container"
                to="campaign"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        className="features_list_img"
                        layout="constrained"
                        alt="Create Campaign"
                        src="../../images/features/campaign.png"
                      />
                    </div>
                    <span className="text-base pt-1">Create Campaign</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="win" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="Automatic drawing"
                        src="../../images/features/win.png"
                      />
                    </div>
                    <span className="text-base pt-1">Automatic drawing</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="mail" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="Notification of Results"
                        src="../../images/features/mail.png"
                      />
                    </div>
                    <span className="text-base pt-1">
                      Notification of Results
                    </span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="report" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="Report function"
                        src="../../images/features/report.png"
                      />
                    </div>
                    <span className="text-base pt-1">Report function</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll
                className="function_container"
                to="location"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="Address collection"
                        src="../../images/features/location.png"
                      />
                    </div>
                    <span className="text-base pt-1">Address collection</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-10 pt-5 bg-white">
            <p className="md:text-2xl my-1 text-xl">
              ＼ Download our materials now! ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              Download a 3-minute document
              <br />
              to learn about PARKLoT's features(free)
            </a>
          </section>

          <section className="function_detail">
            <div id="campaign" className="function_green">
              <h2 className="function_subtitle">
                Easy campaign setup
                <br />
                from the management screen
              </h2>
              <p className="function_text">
                Instant Win Campaigns can be held on the condition of Follow
                &amp; Retweet.
                <br />
                It can be implemented in as little as one day.
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="Easy campaign setup from the management screen"
                  src="../../images/features/section01.png"
                />
              </div>
            </div>

            <div id="win" className="function_white">
              <h2 className="function_subtitle">No tedious lottery process</h2>
              <p className="function_text">
                Winners will be drawn at random from among the applicants.
                <br />
                Filtering can be used to narrow down the minimum number of
                followers.
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="面倒な抽選作業はゼロ"
                  src="../../images/features/section02.png"
                />
              </div>
            </div>

            <div id="mail" className="function_green">
              <h2 className="function_subtitle">
                Automatic DM sending of lottery results
              </h2>
              <p className="function_text">
                Drawing results will be automatically sent to participants via
                immediate DM.
                <br />
                It can also be sent in batches to the winners by lottery at a
                later date.
                <br />
                No DM sending work is required.
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="Automatic DM sending of lottery results"
                  src="../../images/features/section03.png"
                />
              </div>
            </div>

            <div id="report" className="function_white">
              <h2 className="function_subtitle">
                Reporting function eliminates collection work
              </h2>
              <p className="function_text">
                Automatically collects follower count trends, number of
                applications, etc.
                <br />
                You can check it anytime from the management screen.
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="Reporting function eliminates collection work"
                  src="../../images/features/section04.png"
                />
              </div>
            </div>

            <div id="location" className="function_green">
              <h2 className="function_subtitle">Easy address collection</h2>
              <p className="function_text">
                For physical gifts, winners can register their shipping address
                on a dedicated form.
                <br />
                Registered addresses can be exported to csv files in batches.
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="Easy address collection"
                  src="../../images/features/section05.png"
                />
              </div>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-20 mt-14 pb-20">
            <p className="md:text-2xl my-1 text-xl">
              ＼ Download the document now! ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              Download a 3-minute document
              <br />
              to learn about PARKLoT's features (free)
            </a>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Features
